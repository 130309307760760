window.Card = class {

    static changeTypePerson(event)
    {
        document.querySelector('input[name=cnpj]').value = null;
        document.querySelector('input[name=cpf]').value = null;

        if (event.target.value === 'fisica') {
            document.querySelector('.typePersonJuridica').classList.add('hidden')
            document.querySelector('.typePersonFisica').classList.remove('hidden')
        } else {
            document.querySelector('.typePersonJuridica').classList.remove('hidden')
            document.querySelector('.typePersonFisica').classList.add('hidden')
        }
    }

    static confirmAction(event)
    {
        this.cardId = event.target.dataset.id;
        this.cardStatus = event.target.dataset.status;
        this.modalAtiveOrInactive = new boostrap.Modal(document.getElementById('activeOrInactiveCard'));

        let modalTitle = this.cardStatus == 2
            ? 'Informe o motivo da ativação do cartão.'
            : 'Informe o motivo na inativação do cartão.'

        this.modalAtiveOrInactive._element.querySelector('.modal-title').innerText = modalTitle;
        this.modalAtiveOrInactive.show();
    }

    static activeOrInactive()
    {
        this.message = document.querySelector('textarea').value;
        if (this.message === undefined || this.message === '') {
            return FlexModal.show({
                title: 'Atenção',
                theme: "warning",
                content: 'Informe o motivo para prosseguir com a operação!',
            });
        }

        this.modalAtiveOrInactive.hide();
        this.dispatch();
    }

    static dispatch()
    {
        let endpoint = this.cardStatus == 2 ? `cards/activate/${this.cardId}` : `cards/inactivate/${this.cardId}`
        let form = new FormData();
        form.append('_token', document.querySelector('input[name=_token]').value);
        form.append('message', this.message);
        FlexLoader.show('Aguarde...');

        fetch(endpoint, { method: 'POST', body: form })
            .then((response) => response.json())
            .then(data => {
                if (data.status) {

                    return FlexModal.show({
                        title: 'Sucesso',
                        theme: "success",
                        content: data.result,
                        onClickBtnClose () {
                            FlexLoader.show();
                            window.location.reload();
                        }
                    });
                }

                throw new Error(data.error);
            })
            .catch((e) => {
                FlexLoader.hide()
                FlexModal.show({
                    theme: "warning",
                    title: "Aviso",
                    content: e.message
                })
            })
            .finally(() => FlexLoader.hide());
    }
}